export const getStrapiBiases = async () => {
  const url = `${process.env.NEXT_PUBLIC_STRAPI}/api/biases?locale=en&sort=number&pagination[pageSize]=100&pagination[page]=`;
  const urlRu = `${process.env.NEXT_PUBLIC_STRAPI}/api/biases?locale=ru&sort=number&pagination[pageSize]=100&pagination[page]=`;
  const result = await Promise.all([
    fetch(`${url}1`)
      .then(data => data.json())
      .then(data => data.data),
    fetch(`${url}2`)
      .then(data => data.json())
      .then(data => data.data),
    fetch(`${urlRu}1`)
      .then(data => data.json())
      .then(data => data.data),
    fetch(`${urlRu}2`)
      .then(data => data.json())
      .then(data => data.data),
  ]);
  return {
    en: [...result[0], ...result[1]],
    ru: [...result[2], ...result[3]],
  };
};
