const en = [
  {
    id: 1,
    from: 1,
    to: 33,
    label: 'Too much information',
  },
  {
    id: 2,
    from: 34,
    to: 68,
    label: 'Not enough meaning',
  },
  {
    id: 3,
    from: 69,
    to: 97,
    label: 'Need to act fast',
  },
  {
    id: 4,
    from: 98,
    to: 105,
    label: 'What should we remember?',
  },
];

export default en;
