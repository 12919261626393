const ru = [
  {
    id: 1,
    from: 1,
    to: 33,
    label: 'Когда много информации',
  },
  {
    id: 2,
    from: 34,
    to: 68,
    label: 'Когда не хватает смысла',
  },
  {
    id: 3,
    from: 69,
    to: 97,
    label: 'Когда быстро реагируем',
  },
  {
    id: 4,
    from: 98,
    to: 105,
    label: 'Когда запоминаем и вспоминаем',
  },
];

export default ru;
