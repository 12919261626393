import type {MutableRefObject} from 'react';
import biasesCategories from '@data/biasesCategories';
import type {BiasType, QuestionType, StrapiBiasType} from '@local-types/data';
import {downloadLinksEn, downloadLinksRu} from '@api/questions';

export function scrollToImage(src: string) {
  function getOffset(el: HTMLImageElement) {
    var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {top: rect.top + scrollTop, left: rect.left + scrollLeft};
  }

  const image: HTMLImageElement = document.querySelector(`img[src='${src}']`);
  const offset = getOffset(image);
  window.scrollTo(0, offset.top - 50);
}

const globalNavigator = typeof navigator !== 'undefined' && navigator;
const globalWindow = typeof window !== 'undefined' && window;

const isBrowser = () =>
  typeof window !== 'undefined' &&
  typeof navigator !== 'undefined' &&
  typeof document !== 'undefined';

const isAndroid = () =>
  isBrowser() && /(android)/i.test(globalNavigator.userAgent);

const isIOS = () =>
  isBrowser() &&
  !!globalNavigator.userAgent.match(/iPad|iPhone|iPod/i) &&
  // @ts-ignore
  !globalWindow.MSStream;
export const isMobileDevice = () => isIOS() || isAndroid();

const getSectionID = (currentBiasData: StrapiBiasType, id: number) => {
  if (!currentBiasData) return null;

  if (id < 34) {
    return 1;
  } else if (id >= 34 && id < 69) {
    return 2;
  } else if (id >= 69 && id < 98) {
    return 3;
  } else {
    return 4;
  }
};

export const generateLabelsData = (
  biases: StrapiBiasType[],
  biasNumber: number,
  position: 'left' | 'right',
) => {
  let topBiasNumber: number;
  let bottomBiasNumber: number;

  if (position === 'left') {
    topBiasNumber = biasNumber === 105 ? 999 : biasNumber + 1;
    bottomBiasNumber = biasNumber === 53 ? 999 : biasNumber - 1;
  } else {
    topBiasNumber = biasNumber === 1 ? 999 : biasNumber - 1;
    bottomBiasNumber = biasNumber === 52 ? 999 : biasNumber + 1;
  }

  const topBias = biases.find(({number}) => number === topBiasNumber);
  const currentBias = biases.find(({number}) => number === biasNumber);
  const bottomBias = biases.find(({number}) => number === bottomBiasNumber);

  return [
    {
      id: topBiasNumber,
      sectionId: getSectionID(topBias, topBiasNumber),
      data: topBias,
    },
    {
      id: biasNumber,
      sectionId: getSectionID(currentBias, biasNumber),
      data: currentBias,
    },
    {
      id: bottomBiasNumber,
      sectionId: getSectionID(bottomBias, bottomBiasNumber),
      data: bottomBias,
    },
  ];
};

const searchPostfixes = {
  en: [
    ['', 's'],
    ['', ''],
    ['', 's'],
  ],
  ru: [
    ['о', 'ов'],
    ['', ''],
    ['о', 'а'],
  ],
};

const getSearchLabels = (length: number, locale: 'en' | 'ru') => {
  const mainNumber = length % 100;
  const lastNumber = mainNumber % 10;

  if (mainNumber >= 10 && mainNumber < 20) {
    return searchPostfixes[locale][0];
  } else {
    if (lastNumber == 1) return searchPostfixes[locale][1];
    else if (lastNumber > 1 && lastNumber < 5)
      return searchPostfixes[locale][2];
    else return searchPostfixes[locale][0];
  }
};

export const getSearchResults = (
  biases: any[],
  searchValue: string,
  locale: 'en' | 'ru',
) => {
  let results: any[] = [];
  const isEng = locale === 'en';

  if (searchValue.trim()) {
    // @ts-ignore
    results = biases.reduce(
      (
        acc,
        {number, titleEn, titleRu, usageEn, usageRu, descrEn, descrRu, p},
      ) => {
        const title = isEng ? titleEn : titleRu;
        let usage = isEng ? usageEn : usageRu;
        let description = isEng ? descrEn : descrRu;
        if (
          title.toLocaleLowerCase().indexOf(searchValue) > -1 ||
          usage.toLocaleLowerCase().indexOf(searchValue) > -1 ||
          description.toLocaleLowerCase().indexOf(searchValue) > -1
        ) {
          acc.push(number);
        }
        return acc;
      },
      [],
    );
  }

  const searchLabels = getSearchLabels(results.length, locale);
  return {results, searchLabels};
};

export function groupFilteredData(
  biases: StrapiBiasType[],
  searchResults: any[],
  locale: 'en' | 'ru',
) {
  let groups: any = [];
  let filters = searchResults;

  if (!searchResults.length) {
    // @ts-ignore
    filters = biases.map(bias => bias.number);
  }

  biasesCategories[locale].reduce((acc, cat, index) => {
    filters.forEach(biasNumber => {
      if (biasNumber >= cat.from && biasNumber <= cat.to) {
        if (!acc[index]) acc[index] = [];
        acc[index].push(biasNumber);
      }
    });

    return acc;
  }, groups);

  const sortedGroups = groups.map((group: number[]) =>
    group.sort((a, b) => {
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    }),
  );

  return sortedGroups;
}

export const generateSocialLinks = (url: string, title: string) => ({
  linkedIn: `http://www.linkedin.com/shareArticle?url=${url}&title=${title}`,
  facebook: `https://www.facebook.com/sharer/sharer.php?title=${title}&u=${url}`,
  tweeter: `http://twitter.com/share?url=${url}&text=${title}`,
});

export const copyToClipboard = (str: string) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

// TODO: make this passive functionality to use VH in another components
export const updateVH = () => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

export const getRatedItems = (type: 'bias' | 'question') => {
  const key = type === 'bias' ? 'ratedBiases' : 'ratedQuestions';
  return JSON.parse(localStorage.getItem(key)) || [];
};

export const saveInLocalStorage = (id: number, type: 'bias' | 'question') => {
  const key = type === 'bias' ? 'ratedBiases' : 'ratedQuestions';
  const ratedItems = getRatedItems(type);

  if (!ratedItems.includes(id)) {
    ratedItems.push(id);
    localStorage.setItem(key, JSON.stringify(ratedItems));
  }
};

export function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const sortByNumber = (
  a: QuestionType | BiasType,
  b: QuestionType | BiasType,
) => {
  if (a.number === null || b.number === null) return 0;
  if (a.number > b.number) return 1;
  if (a.number < b.number) return -1;
  return 0;
};

export const calculateTooltipPostion = (
  left: number,
  top: number,
  bottom: number,
  blockWidth: number,
  tooltipRef: MutableRefObject<any>,
  isOnBottom: boolean,
) => {
  const {width: tooltipWidth, height: tooltipheight} =
    // @ts-ignore
    tooltipRef.current.getBoundingClientRect();
  // const linkHeight = 17;
  const arrowGap = 6;
  const calculatedTop = isOnBottom
    ? bottom + arrowGap
    : top - tooltipheight - arrowGap;
  /**
   * Left Calculation Formula:
   * half of outside part = (tooltip width - block width) / 2
   * left = block left - half of outside part
   */

  const outsideHalfPart = (tooltipWidth - blockWidth) / 2;
  const calculatedLeft = left - outsideHalfPart;
  
  return {left: calculatedLeft, top: calculatedTop};
};

export const generateQuestionsSeo = (questionsData: QuestionType[]) => {
  return questionsData.reduce(
    (acc, question): any => {
      const path = `/uxcg/${question.number}`;
      acc.en[path] = {
        title: question.seoTitleEn,
        description: question.seoDescriptionEn,
        keywords: question.keywordsEn,
        pageTitle: question.pageTitleEn,
      };

      acc.ru[path] = {
        title: question.seoTitleRu,
        description: question.seoDescriptionRu,
        keywords: question.keywordsRu,
        pageTitle: question.pageTitleRu,
      };

      return acc;
    },
    { en: {} as any, ru: {} as any },
  );
};

export const mergeBiasesLocalization = (en: any[], ru: any[]) => {
  const result: StrapiBiasType[] = [];
  en.forEach((item: any, index: number) => {
    result.push({
      seoTitleEn: item?.attributes?.seoTitle || null,
      seoTitleRu: ru[index]?.attributes?.seoTitle || null,
      pageTitleEn: item?.attributes?.pageTitle || null,
      pageTitleRu: ru[index]?.attributes?.pageTitle || null,
      keywordsEn: item?.attributes?.keywords || null,
      keywordsRu: ru[index]?.attributes?.keywords || null,
      seoDescriptionEn: item?.attributes?.seoDescription || null,
      seoDescriptionRu: ru[index]?.attributes?.seoDescription || null,
      descrEn: item?.attributes?.description || null,
      descrRu: ru[index]?.attributes?.description || null,
      number: +item?.attributes?.number || null,
      titleEn: item?.attributes?.title || null,
      titleRu: ru[index]?.attributes?.title || null,
      usageEn: item?.attributes?.usage || null,
      usageRu: ru[index]?.attributes?.usage || null,
      usageHrEn: item?.attributes?.usageHr || null,
      usageHrRu: ru[index]?.attributes?.usageHr || null,
      usageUiUxEn: item?.attributes?.usageUiUx || null,
      usageUiUxRu: ru[index]?.attributes?.usageUiUx || null,
      mentionedQuestionsIds: item?.attributes?.mentionedQuestionsIds || null,
      _id: item?.id || null,
      wikiLinkEn: item?.attributes?.wikiLink || null,
      wikiLinkRu: ru[index]?.attributes?.wikiLink || null,
      m: item?.attributes?.m || false,
      p: item?.attributes?.p || false,
    });
  });

  return result;
};
export const mergeQuestionsLocalization = (en: any[], ru: any[]) => {
  const result: any[] = [];
  en.forEach((item: any, index: number) => {
    result.push({
      number: +item?.attributes?.number || null,
      titleEn: item?.attributes?.title || null,
      titleRu: ru[index]?.attributes?.title || null,
      answersEn: item?.attributes?.answers || [],
      answersRu: ru[index]?.attributes?.answers || [],
      tags: JSON.parse(item?.attributes?.tags) || [],
      downloadLinks: {
        en: downloadLinksEn[+item?.attributes?.number - 1],
        ru: downloadLinksRu[+item?.attributes?.number - 1],
      },
      aliases:
        [item?.attributes?.aliases, ru[index]?.attributes?.aliases] || null,
      relatedQuestions: JSON.parse(item?.attributes?.relatedQuestions) || [],
      seoTitleEn: item?.attributes?.seoTitle,
      seoTitleRu: ru[index]?.attributes?.seoTitle,
      keywordsEn: item?.attributes?.keywords,
      keywordsRu: ru[index]?.attributes?.keywords,
      seoDescriptionEn: item?.attributes?.seoDescription,
      seoDescriptionRu: ru[index]?.attributes?.seoDescription,
      pageTitleEn: item?.attributes?.pageTitle,
      pageTitleRu: ru[index]?.attributes?.pageTitle,
    });
  });

  return result;
};
