import React, { useEffect, KeyboardEvent, useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import { useRouter } from 'next/router';

import type { TRouter } from '@local-types/global';
import useFormPopup from '@hooks/useFormPopup';
import formPopupIntl from '@data/formPopup';
import Button from '@components/Button';

import styles from './FormPopup.module.scss';

const FormPopup = () => {
  const [{ togglePopupVisibiity }, { isVisible }] = useFormPopup();
  const router = useRouter();
  const { locale } = router as TRouter;
  const [container, setContainer] = useState(null);
  const { title, buttonLabel } = formPopupIntl[locale];

  const handleClose = useCallback(() => {
    if (isVisible) {
      togglePopupVisibiity();
    }
  }, [togglePopupVisibiity, isVisible]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') handleClose();
    };

    // @ts-ignore
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      // @ts-ignore
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleClose]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setContainer(document.body);
    }
  }, []);

  if (!isVisible || !container) return null;

  return (
    <>
      {createPortal(
        <div className={styles.ModalOverlay} onClick={handleClose}>
          <div className={styles.Modal} onClick={e => e.stopPropagation()}>
            <div className={styles.ModalHeader}>
              <div className={styles.ModalHeaderBody} />
              <div className={styles.ModalHeaderCloseButtonContainer}>
                <div className={styles.ModalHeaderCloseButton}>
                  <img
                    src="/assets/biases/cross.svg"
                    onClick={handleClose}
                    alt="modal close button"
                  />
                </div>
              </div>
            </div>
            <div className={styles.ModalBody}>{title}</div>
            <div className={styles.ModalFooter}>
              <Button label={buttonLabel} type="primary" onClick={handleClose} />
            </div>
          </div>
        </div>,
        container,
      )}
    </>
  );
};

export default FormPopup;
